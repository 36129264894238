
html, body {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    width: 100%;
    display: table;
    font-weight: 100;
    font-family: 'Lato';
}

.container {
    text-align: center;
    display: block;
    vertical-align: middle;
    margin: 0 auto;
}



.title {
    font-size: 96px;
}
            
            
/*
 
 The Loading Kid 
 
 */
.transition, body .baby, body .baby * {
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

body .baby {
  position: absolute;
  bottom: 5%;
  right: 10%;
  margin-left: -50px;
  margin-top: -20px;
}
body .baby .back {
  width: 75px;
  height: 20px;
  background: #ff6b24;
  position: absolute;
  bottom: 5px;
  left: 35px;
  z-index: -1;
}
body .baby .back .tail {
  width: 5px;
  height: 5px;
  position: absolute;
  background: #ff6b24;
  top: -22px;
  right: 13px;
  border-radius: 50%;
  transform: rotate(20deg);
}
body .baby .back .feet {
  background: #ff6b24;
  width: 15px;
  height: 27px;
  position: absolute;
  right: -6px;
  border-radius: 50%;
}
body .baby .back .hand {
  background: #ff5402;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  width: 52px;
  height: 8px;
  top: 15px;
  z-index: 1;
}
body .baby .back .hand:after {
  content: " ";
  width: 16px;
  height: 13px;
  position: absolute;
  background: #ff5402;
  right: -7px;
  top: -2px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
body .baby .back .ass {
  position: absolute;
  width: 30px;
  height: 40px;
  background: #ff6b24;
  left: 25px;
  border-radius: 50%;
  bottom: 0px;
  -moz-transform: skew(-30deg);
  -ms-transform: skew(-30deg);
  -webkit-transform: skew(-30deg);
  transform: skew(-30deg);
}
body .baby .back .ass:after {
  content: " ";
  position: absolute;
  width: 30px;
  height: 40px;
  background: #ff6b24;
  right: -15px;
  border-radius: 50%;
  bottom: 0px;
  -moz-transform: skew(30deg);
  -ms-transform: skew(30deg);
  -webkit-transform: skew(30deg);
  transform: skew(30deg);
  -moz-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
}
body .baby .head {
  width: 60px;
  height: 60px;
  background: #ff6b24;
  -moz-border-radius: 50% 60% 50% 40%;
  -webkit-border-radius: 50%;
  border-radius: 50% 60% 50% 40%;
}
body .baby .head .eye {
  width: 10px;
  height: 2px;
  background: #000;
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
  border-radius: 1px;
  position: absolute;
  top: 40px;
  left: 6px;
}
body .baby .head .cheek {
  width: 10px;
  height: 4px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #df0009;
  position: absolute;
  top: 44px;
  left: 13px;
}
body .baby .head .horn {
  width: 10px;
  height: 18px;
  background: #ff6b24;
  position: absolute;
  top: -8px;
  left: 18px;
  -moz-border-radius: 130% 30% 0% 0%;
  -webkit-border-radius: 130%;
  border-radius: 130% 30% 0% 0%;
  -moz-transform: rotate(-20deg);
  -ms-transform: rotate(-20deg);
  -webkit-transform: rotate(-20deg);
  transform: rotate(-20deg);
}
body .baby.down .back {
  width: 85px;
}
body .baby.down .back .tail {
  top: -10px;
  right: 26px;
}
body .baby.down .back .ass {
  height: 25px;
}
body .baby.down .back .ass:after {
  height: 28px;
  width: 36px;
}
body .baby.down .back .feet {
  height: 24px;
  top: -1px;
}
body .baby.down .back .hand {
  width: 45px;
}
body .baby.down .back .hand:after {
  height: 11px;
  top: -1px;
}
body .baby.down .head {
  -moz-border-radius: 70% 60% 60% 40%;
  -webkit-border-radius: 70%;
  border-radius: 70% 60% 60% 40%;
  margin-left: -5px;
}
body .baby.down .head .eye {
  left: 0;
}
body .baby.down .head .cheek {
  left: 8px;
}
body .baby.down .head .horn {
  -moz-transform: rotate(20deg);
  -ms-transform: rotate(20deg);
  -webkit-transform: rotate(20deg);
  transform: rotate(20deg);
}

body .baby .loading{
	position: absolute;
	left: 40px;
}


/*******************************/

.navbar-default .container{
	width: 100%;
	display: block;
}


@mixin shimmer($background-color:#000, $shimmer-color:#fff, $duration:2.0s) {
  background: linear-gradient(to right, $background-color 41%, darken($shimmer-color, 10%) 49%, $shimmer-color, darken($shimmer-color, 10%) 52%, $background-color 60%);
  background-size: 200% 100%;
  background-repeat: no-repeat;
  background-position: 100% 0;
  -webkit-background-clip: text;
  text-fill-color: rgba(0, 0, 0, 0.0);
  animation: shimmer $duration linear 0s infinite normal;
  text-align: center;
}

@keyframes shimmer {
  0% { background-position-x: 100%; }
  100% { background-position-x: 0; }
}

.shimmer {
  font-size: 3em;
  @include shimmer($background-color:#800080, $shimmer-color:#E9DDCC, $duration:2.0s);
}


.content {
    text-align: center;
    display: inline-block;
}

.element{
	color: #a9144c;
	background: white;
}


.about{
	h2, p, ul, li{
		text-align: left;
	}
	p, li{
		color: #000;
		font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
		font-weight: 300; 
	}
	ul{
		
	}
}

.footer {
		width: 100%;
		  height: 60px;
		  
	&.sticky{
		position: absolute;
		bottom: 0;  
		  
	}
  
}
